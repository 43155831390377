.Typist {
  display: inline;
}

li.active > a {
  color: #2f55d4 !important;
}

.pagination .active a {
  color: #ffffff !important;
}

a {
  &:hover {
    cursor: pointer;
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 12px;
  height: 12px;
  margin: 3rem 0.2rem;
  background: #8385aa;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.accordian-custom {
  border: none;
}

#mt-1 {
  margin-top: 5px !important;
}

#mb-1 {
  margin-top: 5px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.masonry-container {
  width: 100%;
  margin: auto;
}
.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
}
.tile {
  img {
    width: 100%;
  }
}

.indicator-inactive,
.indicator-active {
  height: 12px !important;
  width: 6px !important;
  border-radius: 4px;
  border: none;
  margin-left: 7px;
  margin-right: 7px;
}

.indicator-inactive {
  background-color: #2f55d4 !important;
  opacity: 0.6;
}
.indicator-active {
  background-color: #2f55d4 !important;
  transform: rotate(45deg);
}

.custom-owl-dots {
  margin-top: 10px;
  text-align: center;
}

.classForContainer {
  position: fixed;
  right: -100px;
  bottom: 30px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: rgba(47, 85, 212, 0.2);
  padding: 5px;
  align-items: center;
  border-radius: 5px !important;
  outline: none;

  &:hover {
    background-color: $primary !important;
    svg {
      stroke: $white !important;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    overflow: hidden;
    margin-top: 2px;
    margin-left: 5px;
    fill: none;
    stroke: $primary !important;
  }
}

.classForTransition {
  right: 30px;
}
//masonry

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.accordion {
  .card {
    .faq {
      > .active-link {
        color: $primary !important;
      }
    }
  }
}

.hover-border {
  .nav-link {
    &:focus,
    &.active,
    &:active {
      outline: none !important;
      border: transparent !important;
    }
    &:hover {
      border-color: transparent !important;
    }
  }
}
.custom-dots {
  .indicator-active {
    background-color: #2f55d4 !important;
  }
}
.custom-dots {
  .indicator-inactive {
    background-color: #6486e4 !important;
  }
}

.close {
  background: transparent;
  border: 0;
  font-size: 24px;
  padding: 1.35rem 1.25rem;
  color: #000 !important;
  background: transparent
    escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
    )
    center / 1em auto no-repeat;
  position: absolute !important;
  top: -7px;
  right: 20px;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
}

[class^='uil-'],
[class*=' uil-'] {
  &:before {
    margin: 0;
  }
}
.footer-border-bottom {
  border-bottom: 1px solid #283353;
}

.nav-light{
  .wa-css{
    span, i, svg{
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
.nav-sticky{
  .wa-css{
    span, i, svg{
      color: #161c2d;
    }
  }
}
.wa-css{
  border:none;
  background: none;
  span, i{
    color: #161c2d;
  }
}
.watop{
  font-size: 13px;
}
@media (min-width: 991px){
#top-menu{
  position: absolute;
  left: 0; 
  right: 0; 
  margin-left: auto !important; 
  margin-right: auto !important; 
  width: 690px;
}
}
.num-line1{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.num-line2{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.num-line3{
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.mod-text{
  font-size: 14px;
}

@media (max-width: 991px){
#auth-btn{
  display: none;
}
}

h1.title-detail, h2.title-detail{
  font-size: 24px !important;
}
h1.title-blog{
  font-size: 36px !important;
}
h3.card-title, h2.card-title{
  font-size: 20px !important;
}
.page-next-level h1.title{
  font-size: 28px !important;
}
h2.card-title{
  display: inline-block;
}


.bm-burger-button {
  position: fixed;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 25px;
  display: none;
}


.title-heading h2{
  font-size: 18px !important;
}

/* Position and sizing of burger button */
// X-Small devices (portrait phones, less than 576px)


// Small devices (landscape phones, less than 768px)




// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
  .bm-burger-button {
    display: none;
  }
 }

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { 
  .bm-burger-button {
  display: none;
} 
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
  .bm-burger-button {
    display: block;
  }
 }

@media (max-width: 767.98px) { 
  .bm-burger-button {
    display: block;
  }
  // .card-desc{
  //   padding:10px !important;
  // }
  .hcard-desc{
    padding:15px !important;
  }
  .card-desc h3{
    //font-size:15px !important;
    margin-bottom: 4px;
  }
  .card-desc p{
    //font-size:15px !important;
    margin-bottom: 4px;
  }
  .card-desc a{
    //font-size:15px !important;
  }
  .card-list-row > * {
    padding-right: calc(var(--bs-gutter-x) * 0.3);
    padding-left: calc(var(--bs-gutter-x) * 0.3);
  }
  .page-next-level h1.title{
    font-size: 24px !important;
  }
  .mobile-home .title-heading .heading{
    font-size: 32px !important;
  }
  h2.card-title{
    font-size: 17px !important;
  }
  p,td,td{
    //font-size: 15px !important;
  }
  .mobile-home span.content{
    font-size: 13px !important;
  }
  .mobile-home input{
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .subcribe-form .btn{
    padding: 5px 10px !important;
    font-size: 13px !important;
    margin-top: 7px;
    margin-right: 10px;
  }
  // .explore-feature .icon{
  //   width: 48px !important;
  //   height: 48px !important;
  // }
  .icon-home{
    align-self: center;
    justify-content: center;
    display: flex;
    margin-top: 11px;
  }
  .cat-home{
    margin-left: 8px;
    margin-right: 8px;
    display: inline-block;
  }
  .cat-home h3{
    font-size: 11.5px !important;
  }
  .react-horizontal-scrolling-menu--wrapper{
    margin-top: 20px;
    margin-bottom: 25px;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .mobile-about{
    padding-top: 30px !important;
  }
  .course-mobile{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  a{
    //font-size: 13px !important;
  }
  .form-check a{
    font-size: 11px !important;
  }
  .cover-user .cover-user-img{
    padding: 15px !important;
  }
  .g-sign a{
    font-size: 16px !important;
  }
  // .breadcrumb .breadcrumb-item a{
  //   font-size: 12px !important;
  // }
  .footer h5{
    font-size: 16px !important;
  }
  .cat-card h3{
    font-size: 15px !important;
  }
  .cat-card .card-body{
    padding: 0 !important;
  }
 }

 #buyButton{
  font-size: 13px;
 }

@media (max-width: 575.98px) { 
  .bm-burger-button {
    display: block;
  }
}



/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  left: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.sl-menu-item{
  color: #f8f9fc !important;
  display: block;
  margin-bottom: 10px;
  //font-size: 15px !important;
}
.sl-sub-menu-item{
  display: block;
  color: #f8f9fc;
  margin-top: 10px;
  //font-size: 15px !important;
}
.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 20px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}
.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: translate(0px, -3px) rotate(45deg);
}
/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.faq .accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.faq .accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.faq .accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
}

.faq .accordion__button:hover {
  background-color: #ddd;
}

.faq .accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.faq .accordion__button[aria-expanded='true']::before,
.faq .accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.faq .accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.faq .accordion__item {
  margin-bottom: 20px;
}
.faq .accordion{
  border: none !important;
}
.float-wa{
  z-index: 9999;
  position: fixed;
  bottom: 20px;
  right: 40px;
  background: #1BD741;
  border-radius: 15px;
  padding: 10px 15px;
}

.sidebar-nav > .navbar-item button.navbar-link{
  color: #FFF !important;
}

#topnav .navbar-toggle .lines {
  margin-right: 7px !important;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}